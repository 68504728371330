<template>
  <v-card rounded="lg">
    <v-sheet v-if="!date">
      <v-skeleton-loader type="card-avatar, list-item-avatar@7"/>
    </v-sheet>
    <v-sheet  v-if="date">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ $t('label.day') + ': ' + toLocale(date.day) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item
            v-for="event in date.events"
            :key="event.name"
        >
          <v-list-item-icon>
            <v-icon :color="event.color">{{ 'mdi-clock' }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <v-chip>{{ toFormat(event.start) }}</v-chip>
              <v-chip>{{ toFormat(event.end) }}</v-chip>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-card>
</template>
<script>
import { DateTime } from "luxon";
export default {
  props: {
    emittedToAside: {
      type: [Object, String],
      default: null
    }
  },
  watch: {
    emittedToAside(value) {
      this.date = value
    },
  },
  data: () => ({
    date: null
  }),
  methods: {
    toFormat(date) {
      return DateTime.fromSQL(date).toFormat('HH:mm')
    },
    toLocale(date) {
      return DateTime.fromSQL(date).setLocale(this.$i18n.locale).toLocaleString()
    }
  }
}
</script>